import React, { useContext, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import SignaturePad from "react-signature-pad-wrapper"
import ToggleMute from "../components/ToggleMute"

export default function Signature() {
  const { user, saveUser, saveDraftUser } = useContext(UserContext)
  let signatureRef = useRef() //reference hook

  const saveSignature = () => {
    const dataURL = signatureRef.toDataURL()
    saveUser({ signature: dataURL })
  }

  const clearSignature = () => {
    signatureRef.clear && signatureRef.clear()
    saveUser({ signature: null })
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const handleFile = async e => {
    const file = e.target.files[0]
    try {
      const signature = await toBase64(file)
      saveUser({ signature })
    } catch (error) {
      saveUser({ signature: null })
    }
  }

  return (
    <Background container>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold ml-4 mt-4">Signature</h1>
        <h3 className="mb-4 text-center">
            Signing here indicates your knowledge of and consent to the terms.
          <br />
          You will not be able to continue unless a signature is provided.
        </h3>

        <p className="text-center">
          Please sign on the white canvas and tap
          <span className="ml-4">
            <Button
              text="Save"
              size="small"
              onClick={() => {
                saveSignature()
              }}
            />
          </span>
          when you are done. You can
          <span className="ml-4">
            <Button
              size="small"
              text="Clear"
              onClick={() => {
                clearSignature()
              }}
            />
          </span>
          the canvas if you wish.
        </p>

        <div className="flex mb-4"></div>

        {!user.signature ? (
          <div style={{ marginBottom: 50 }}>
            <div className="p-4" style={{ backgroundColor: "white" }}>
              <SignaturePad
                height={180}
                width={450}
                ref={ref => (signatureRef = ref)}
              />
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: 50 }}>
            <img src={user.signature} className="p-4" style={{ height: 180 }} />
          </div>
        )}

        <p className="text-center">OR attach a photo of the signature.</p>

        <div style={{ marginLeft: 128 }}>
          <input name="signature" type="file" onChange={handleFile} />
        </div>

        <div className="d-flex flex-row-reverse fixed-top p-4">
          <ToggleMute
            isActive={user.isSoundActive}
            onClick={() => {
              saveUser({
                ...user,
                isSoundActive: !user.isSoundActive,
              })
              if (user.isSoundActive) {
                user.audio && user.audio.pause && user.audio.pause()
              } else {
                user.audio && user.audio.play && user.audio.play()
              }
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={!user.signature}
            onClick={() => {
              saveDraftUser(user)
              navigate("/select-payout-methods")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-3d")}
          />
        </div>
      </div>
    </Background>
  )
}
